import Bugsnag from "@bugsnag/js";
import Firebase from "config/Firebase";
import 'firebase/compat/storage';
import { v4 } from 'uuid';

/// Takes care of uploading files to the Firebase Cloud Storage.
class FileUploader {

  private readonly firebase: Firebase;

  private readonly maxFileSize: number;
  
  private readonly onUploadProgress?: ((file: File, progress: number) => void) | null;

  constructor(firebase: Firebase, maxFileSize: number, onUploadProgress: ((file: File, progress: number) => void) | null) {
    this.firebase = firebase;
    this.maxFileSize = maxFileSize;
    this.onUploadProgress = onUploadProgress;
  }

  async uploadFile(file: File, path: string) {
    if (this.isFileTooLarge(file)) {
      throw new Error(`Datoteka ${file.name} je prevelika. Naložite lahko le datoteke manjše od ${(this.maxFileSize / 1024 / 1024).toFixed(0)} MB.`);
    }
    
    const fileId = v4();
    const pathWithFileId = `${path}/{${fileId}}`;
    const imageRef = this.firebase.storage().ref(pathWithFileId);
    
    try {
      // Upload file
      const uploadTask = imageRef.put(file);
      uploadTask.on('state_changed', snapshot => {
        if (this.onUploadProgress) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.onUploadProgress(file, progress);
        }
      });

      const snapshot = await uploadTask;
      console.log(`Uploaded a file with ID: ${fileId}`);
      
      // Set it's filename (this way we assure that the filename when user downloads this attachment is the same as it was when he uploaded it)
      const newMetadata = {
        contentDisposition : "attachment; filename=" + file.name
      }
      await imageRef.updateMetadata(newMetadata)
      
      // Get the download url of the uploaded image
      const url = await snapshot.ref.getDownloadURL();
      console.log(`Url of file with ID ${fileId}: ${url}`);
      return url as string;
    }
    catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      console.log(`Error while uploading file to firebase storage: ${error}`);
      throw error;
    }
  }

  isFileTooLarge(file: File) {
    return file.size > this.maxFileSize;
  }

}

export default FileUploader;
