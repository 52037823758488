import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import CreateButton from 'components/CreateButton';
import FilterButton from 'components/FilterButton.js';
import List from 'components/List';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import useLiveCollection from 'hooks/useLiveCollection';
import { useAuth } from 'utils/Auth';
import InvoiceModel from 'models/InvoiceModel.js';
import Localizer from 'utils/Localizer.js';
import InvoiceNumberProvider from 'utils/InvoiceNumberProvider';
import InvoiceStatusBox from 'components/documents/invoice/InvoiceStatusBox';
import EmptyView from 'components/EmptyView';
import InvoiceStats from 'components/InvoiceStats';
import DocumentsFilterBar from 'components/DocumentsFilterBar';
import { useFirebase } from 'config/Firebase';
import UpgraderClient from 'utils/UpgraderClient';
import InvoiceServiceDateTextFormatter from 'utils/InvoiceServiceDateTextFormatter';
import useDocumentsFilter from 'hooks/useDocumentsFilter';
import ListDataSource from 'types/ListDataSource';
import SortDirection from 'types/SortDirection';
import SortOption from 'types/SortOption';
import Organization from 'types/Organization';
import InvoiceStatus from 'types/InvoiceStatus';
import InvoiceTypeProvider from 'utils/InvoiceTypeProvider';
import InvoiceTypeLocalizer from 'utils/InvoiceTypeLocalizer';
import InvoiceProvider from 'providers/InvoiceProvider';
import SubscriptionInfo from 'components/SubscriptionInfo';
import useSubscriptionInfo from 'hooks/useSubscriptionInfo';
import SubscriptionInfoType from 'types/SubscriptionInfoType';
import OrganizationSubscriptionSource from 'types/OrganizationSubscriptionSource';
import Bugsnag from '@bugsnag/js';

const Documents = () => {

  const onFilterCleared = () => {
    resetOrderBy();
  }
  
  const defaultSortOption = { fieldName: 'createdAt', direction: SortDirection.Descending };
  const { userData, currentUser, organizationData } = useAuth();
  const firebaseContext = useFirebase();
  const filter = useDocumentsFilter(onFilterCleared);
  const { snapshot, pager, setOrderByField, setOrderByDirection } = useLiveCollection('invoices', undefined, undefined, undefined, filter.queryHandle);
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>(defaultSortOption);
  const { subscriptionInfoType } = useSubscriptionInfo();
  const { createBackendRequest } = useAuth();

  useEffect(() => {
    firebaseContext!.firebase.logEvent('view_item_list', { item_category: 'documents' });

    try {
      const axiosInstance = createBackendRequest();
      const upgraderClient = new UpgraderClient(axiosInstance);
      upgraderClient.upgradeIfNeeded(currentUser.uid);
    }
    catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      console.log(`Error while upgrading: ${error}`);
    }
  }, [firebaseContext, currentUser, createBackendRequest]);
      
  if (!userData || !snapshot) {
    return <></>
  }
  
  let rows: any[] = [];
  snapshot.forEach(document => {
    const rawData = document.data();
    const data = new InvoiceProvider().get(rawData);
    const invoiceModel = new InvoiceModel(data);
    const summary = invoiceModel.getSummary();
    const invoice = {
      id: document.id,
      href: Router.route(Routes.documents, { documentId: document.id }),
      invoiceNumber: InvoiceNumberProvider.getInvoiceNumberText(data.invoiceNumber, data.invoiceNumberYear, data.invoiceNumberPrefixText),
      partnerName: data.partner.name,
      serviceDate: InvoiceServiceDateTextFormatter.getServiceDateString(data.serviceDate.toDate(), data.endServiceDate ? data.endServiceDate.toDate() : data.serviceDate.toDate(), false, organizationData.cultureCode),
      liquidationDate: data.liquidationDate.toDate().toLocaleDateString(organizationData.cultureCode),
      priceSum: <span className="bold">{Localizer.getNumberString(summary.priceSum, 2) + ' ' + data.currency}</span>,
      status: (<InvoiceStatusBox status={data.status} invoiceId={document.id} />)
    };
    rows.push(invoice);
  });

  const dataSource: ListDataSource = {
    columns: [
      {
        id: 'invoiceNumber',
        header: 'Št. računa',
        sortFieldName: 'createdAt'
      },
      {
        id: 'partnerName',
        header: 'Partner',
        className: 'main',
        sortFieldName: 'partner.name'
      },
      {
        id: 'serviceDate',
        header: 'Datum storitve',
        sortFieldName: 'serviceDate'
      },
      {
        id: 'liquidationDate',
        header: 'Rok plačila',
        sortFieldName: 'liquidationDate'
      },
      {
        id: 'priceSum',
        header: 'Za plačilo',
      },
      {
        id: 'status',
        header: 'Status',
        sortFieldName: 'status',
        className: 'visible-overflow hide-on-mobile'
      },
    ],
    rows: rows
  };

  const manageSubscriptionUrl = Router.route(Routes.organizationSubscriptions, { organizationId: userData.organizationId }, { source: OrganizationSubscriptionSource.CreateButton });
  const invoicesCreatedLimitReached = subscriptionInfoType === SubscriptionInfoType.InvoicesCreatedLimitReached;
  const createUrl = invoicesCreatedLimitReached ? manageSubscriptionUrl : Router.route(Routes.documents, { documentId: 'new' }, { organizationId: userData.organizationId });
  const secondaryActions = InvoiceTypeProvider.getAll().filter(invoiceType => invoiceType !== InvoiceTypeProvider.getDefault()).map((invoiceType) => {
    return {
      to: invoicesCreatedLimitReached ? manageSubscriptionUrl : Router.route(Routes.documents, { documentId: 'new' }, { organizationId: userData.organizationId, typeId: invoiceType.id }),
      text: InvoiceTypeLocalizer.getCreateText(invoiceType.id) };
  });

  const createButton = (
    <CreateButton to={createUrl} secondaryActions={secondaryActions}>Ustvari nov račun</CreateButton>
  );
  
  const emptyViewText = !filter.isAnyFilterApplied ? "V aplikaciji še niste ustvarili nobenega računa." : "Iskalnim kriterijem ne ustreza noben račun.";
  const emptyView = (
    <EmptyView text={emptyViewText} button={!filter.isAnyFilterApplied ? createButton : undefined} />
  );

  const handleSortChange = (sortOption: SortOption) => {
    setOrderByField(sortOption.fieldName);
    setOrderByDirection(sortOption.direction);
    pager.getFirst();
    
    setSelectedSortOption(sortOption);
  };

  const resetOrderBy = () => {
    handleSortChange(defaultSortOption);
  }
  
  const handlePartnerChanged = (organization: Organization | null) => {
    const partner = organization ? {
      id: organization.id,
      name: organization.name
    } : null;
    filter.setPartner(partner);

    resetOrderBy();
  };

  const handleStatusChanged = (status: InvoiceStatus | null) => {
    filter.setStatus(status);

    resetOrderBy();
  };

  const handleInvoiceNumberChanged = (invoiceNumberText: string) => {
    filter.setInvoiceNumberText(invoiceNumberText);

    resetOrderBy();
  };
  
  const statsVisible = !filter.isAnyFilterApplied && !filter.visible;

  const subscriptionInfo = subscriptionInfoType ? (
    <SubscriptionInfo organizationId={userData.organizationId!} infoType={subscriptionInfoType} />
  ) : null;

  return (
    <div className="page documents">
      {subscriptionInfo}
      {createButton}
      <FilterButton onClick={filter.toggleFilter} />
      <DocumentsFilterBar 
        visible={filter.visible}
        isAnyFilterApplied={filter.isAnyFilterApplied}
        partner={filter.partner}
        onPartnerChanged={handlePartnerChanged}
        status={filter.status}
        onStatusChanged={handleStatusChanged}
        invoiceNumber={filter.invoiceNumberText}
        onInvoiceNumberChanged={handleInvoiceNumberChanged}
        onFilterRemoved={filter.clear} />
      <h1 className="hide-on-mobile">Računi</h1>
      <InvoiceStats userId={currentUser.uid} organizationId={userData.organizationId!} visible={statsVisible} />
      <div className="panel">
        <List dataSource={dataSource} pager={pager} emptyView={emptyView} onSortChange={filter.isSortEnabled ? handleSortChange : undefined} sortOption={selectedSortOption} />
      </div>
    </div>
  );
};

export default withRouter(Documents);
