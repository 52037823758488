import React, { useContext } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/app-check';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {

  constructor() {    
    firebase.initializeApp(config);

    // Initialize reCAPTCHA
    if (process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
      const appCheck = firebase.appCheck();
      appCheck.activate(process.env.REACT_APP_RECAPTCHA_SITE_KEY, true);      
    }

    // Initialize analytics
    firebase.analytics();
    
    // Initialize authentication
    const auth = firebase.auth();
    auth.languageCode = 'sl';

    if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === "true") {
      auth.useEmulator("http://localhost:9099");
    }

    // Initialize firestore
    const db = firebase.firestore();
    if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === "true") {
      db.useEmulator("localhost", 8080);
    }

    // Initialize storage
    const storage = firebase.storage();
    if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === "true") {
      storage.useEmulator("localhost", 4002);
    }
  }

  createUserWithEmailAndPassword = (email: string, password: string) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }
  
  signInWithEmailAndPassword = (email: string, password: string) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }
  
  signOut = () => {
    return firebase.auth().signOut();
  }

  sendPasswordResetEmail = (email: string, continueUrl: string) => {
    const actionCodeSettings: firebase.auth.ActionCodeSettings = {
      url: continueUrl
    };
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
  }

  onIdTokenChanged = (nextOrObserver:
    | firebase.Observer<any>
    | ((a: firebase.User | null) => any),
  error?: (a: firebase.auth.Error) => any,
  completed?: firebase.Unsubscribe) : firebase.Unsubscribe => {
    return firebase.auth().onIdTokenChanged(nextOrObserver, error, completed);
  }

  reloadUser = () => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return;
    }

    currentUser.reload();
  }

  onAuthStateChanged = (
    nextOrObserver:
      | firebase.Observer<any>
      | ((a: firebase.User | null) => any),
    error?: (a: firebase.auth.Error) => any,
    completed?: firebase.Unsubscribe
  ) => {
    return firebase.auth().onAuthStateChanged(nextOrObserver, error, completed);
  }

  getIdToken = async (forceRefresh?: boolean) => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      throw new Error('User not signed in');
    }

    return currentUser.getIdToken(forceRefresh);
  }

  logEvent = (
    eventName: string,
    eventParams?: { [key: string]: any },
    options?: firebase.analytics.AnalyticsCallOptions
  ) => {
    firebase.analytics().logEvent(eventName, eventParams, options);
  }

  db = () => {
    return firebase.firestore();
  }

  storage = () => {
    return firebase.storage();
  }

}

export default Firebase;

export interface FirebaseContextInterface {
  firebase: Firebase
}

const context = React.createContext<FirebaseContextInterface | null>(null);
export const FirebaseContextProvider = context.Provider;  

export const useFirebase = () => useContext(context);
