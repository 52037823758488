import { useEffect, useState, useCallback } from 'react';
import { useFirebase } from 'config/Firebase';
import { useAuth } from 'utils/Auth';
import firebase from 'firebase/compat/app';
import SortDirection from 'types/SortDirection';

type QueryDelegate = (query: firebase.firestore.Query<firebase.firestore.DocumentData>) => firebase.firestore.Query<firebase.firestore.DocumentData>

const useLiveCollection = (collectionName: string, defaultOrderByField?: string | undefined, defaultOrderByDirection?: SortDirection | undefined, limit?: number | undefined, queryHandle?: QueryDelegate | undefined, filterByAuthorUid?: boolean | undefined, documentId?: string | undefined, subcollectionName?: string | undefined) => {
  
  const defaultOrderByDirectionNonNullable = defaultOrderByDirection !== undefined ? defaultOrderByDirection : SortDirection.Descending;
  const [orderByField, setOrderByField] = useState(defaultOrderByField);
  const [orderByDirection, setOrderByDirection] = useState<SortDirection>(defaultOrderByDirectionNonNullable);
  const [snapshot, setSnapshot] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] | null>(null);
  const [firstVisibleDocument, setFirstVisibleDocument] = useState<firebase.firestore.DocumentData | null>(null);
  const [lastVisibleDocument, setLastVisibleDocument] = useState<firebase.firestore.DocumentData | null>(null);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [startAt, setStartAt] = useState<firebase.firestore.DocumentData | null>(null);
  const [endAt, setEndAt] = useState<firebase.firestore.DocumentData | null>(null);
  const [loading, setLoading] = useState(false);
  const [invertDirection, setInvertDirection] = useState(false);
  const firebaseContext = useFirebase();
  const filterByAuthorUidNonNullable = filterByAuthorUid !== undefined ? filterByAuthorUid : true;

  const currentUser = useAuth().currentUser;

  const getPrevious = () => {
    setStartAt(null);
    setEndAt(firstVisibleDocument);
    setInvertDirection(true);
  };

  const getNext = () => {
    setEndAt(null);
    setStartAt(lastVisibleDocument);
    setInvertDirection(false);
  };

  const getFirst = () => {
    setStartAt(null);
    setEndAt(null);
    setInvertDirection(false);
  };

  const getLast = () => {
    setStartAt(null);
    setEndAt(null);
    setInvertDirection(true);
  };

  const subscribeToUpdates = useCallback(() => {

    const getInvertDirection = (direction: SortDirection) => {
      if (direction === SortDirection.Descending) {
        return SortDirection.Ascending;
      }
  
      return SortDirection.Descending;
    };

    const getFirestoreDirection = (direction: SortDirection) => {
      if (direction === SortDirection.Ascending) {
        return 'asc';
      }

      if (direction === SortDirection.Descending) {
        return 'desc';
      }
      
      throw new Error('Invalid direction: ' + direction);
    }

    const db = firebaseContext!.firebase.db();
    const orderField = orderByField ? orderByField : 'createdAt';
    const orderDirection = invertDirection ? getInvertDirection(orderByDirection) : orderByDirection;
    const firestoreDirection = getFirestoreDirection(orderDirection);
    const pageSize = limit ? limit : 10;

    let collection = db.collection(collectionName);
    
    if (documentId && subcollectionName) {
      collection = collection.doc(documentId).collection(subcollectionName);
    }

    let query = collection
      .orderBy(orderField, firestoreDirection)
      .limit(pageSize + 1);
    
    if (filterByAuthorUidNonNullable) {
      query = query.where('authorUid', '==', currentUser.uid);
    }

    if (queryHandle) {
      query = queryHandle(query);
    }

    if (startAt) {
      query = query.startAfter(startAt);
    }

    if (endAt) {
      query = query.startAfter(endAt);
    }

    const getList = (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>, pageSize: number) => {
      let documents = [];

      for (let i = 0; i < pageSize; i++) {
        if (i < snapshot.docs.length) {
          documents.push(snapshot.docs[i]);
        }
      }

      if (invertDirection) {
        documents = documents.reverse();
      }
      
      return documents;
    };

    setLoading(true);
    
    return query.onSnapshot(newSnapshot => {
      const list = getList(newSnapshot, pageSize);
      setSnapshot(list);
      setLoading(false);

      if (newSnapshot.docs.length > pageSize) {
        setLastVisibleDocument(list[list.length - 1]);
      } else {
        setLastVisibleDocument(null);
      }

      if (list.length > 0) {
        setFirstVisibleDocument(list[0]);
      } else {
        setFirstVisibleDocument(null);
      }

      const hasNextDocument = newSnapshot.docs.length > pageSize;

      if (invertDirection) {
        setHasPrevious(hasNextDocument);
        setHasNext(endAt !== null);
      } else {
        setHasPrevious(startAt !== null);
        setHasNext(hasNextDocument);
      }
    }, error => {
      console.log(`Error while listening to document updates: ${error}`);
    });
  }, [currentUser, collectionName, subcollectionName, documentId, orderByField, invertDirection, orderByDirection, startAt, endAt, limit, queryHandle, firebaseContext, filterByAuthorUidNonNullable]);
  
  useEffect(() => {
    const unsubscribeFromUpdates = subscribeToUpdates();
    console.log(`subscribeToUpdates: ${collectionName}`);

    return function cleanup() {
      console.log(`unsubscribeFromUpdates: ${collectionName}`);
      unsubscribeFromUpdates();
    };
  }, [subscribeToUpdates, collectionName]);

  const pager = {
    hasPrevious: hasPrevious,
    hasNext: hasNext,
    getPrevious: getPrevious,
    getNext: getNext,
    getFirst: getFirst,
    getLast: getLast,
    loading: loading
  }

  return { snapshot, pager, setOrderByField, setOrderByDirection };

};

export default useLiveCollection;
