import { useEffect, useState } from 'react';
import { useAuth } from 'utils/Auth';
import Bugsnag from '@bugsnag/js';
import useCollection from 'hooks/useCollection.js';
import OrganizationProvider from 'providers/OrganizationProvider';

const useOrganization = (organizationId: string | undefined) => {
  
  const organizationsCollection = useCollection('organizations');
  const [organizationData, setOrganizationData] = useState<any>();
  const auth = useAuth();
  const loading = organizationId && !organizationData;

  const [saving, setSaving] = useState(false);

  const save = async (formData: any) => {    
    setSaving(true);
    
    const rawData = getFormData(formData, organizationData);

    try {
      const savedOrganizationData = await organizationsCollection.addOrUpdate(organizationId, rawData);
      
      const data = new OrganizationProvider().get(rawData);
      if (auth.hasOrganizationSelected && auth.userData.organizationId === organizationId) {
        auth.setOrganizationData(organizationData);
      }
      
      if (!savedOrganizationData) {
        return null;
      }

      return {
        id: savedOrganizationData.id,
        data: data
      };
    }
    catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      console.log('Error while saving organization: ' + error);
      return null;
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {   
    const getOrganization = async () => {
      const rawData = await organizationsCollection.get(organizationId);
      if (!rawData) {
        return;
      }
      
      const data = new OrganizationProvider().get(rawData);
      setOrganizationData(data);
    };
    
    if (organizationId && !organizationData) {
      getOrganization();
    }
  }, [ organizationId, organizationData, organizationsCollection ]);
  
  const getFormData = (formData : any, organizationData : any) => {
    const data = organizationData ? organizationData : {};
    data.name = formData.name;
    data.address = formData.address;
    data.postOffice = formData.zip;
    data.city = formData.city;
    data.country = formData.country;
    data.taxNumber = formData.taxnumber;
    data.taxDebtor = formData.taxdebtor;
    data.bankAccount = formData.bankaccount;
    data.bankBusinessIdentifierCode = formData.bankBusinessIdentifierCode;
    data.countryIssuedId = formData.countryissuedid;
    data.email = formData.email;
    data.phoneNumber = formData.phoneNumber;
    data.invoiceAttribution = formData.invoiceAttribution;
    data.invoiceSignature = formData.invoiceSignature;
    data.invoiceSignatureFileUrl = formData.invoiceSignatureFileUrl;
    data.currency = formData.currency;
    data.accountingEmail = formData.accountingEmail;
    data.liquidationDateDays = parseInt(formData.liquidationDateDays);
    data.visibleVatBreakdownOnPdf = formData.visibleVatBreakdownOnPdf !== undefined ? formData.visibleVatBreakdownOnPdf : formData.taxdebtor; // Undefined when registering new user
    data.visibleQrCodeOnPdf = formData.visibleQrCodeOnPdf !== undefined ? formData.visibleQrCodeOnPdf : true; // Undefined when registering new user
    return data;
  };
  
  return {
    save,
    saving,
    organizationData,
    setOrganizationData,
    loading
  };

};

export default useOrganization;
