import firebase from 'firebase/compat/app';

class CollectionHelper {
  
  private currentUser: firebase.User;

  constructor(currentUser: firebase.User) {
    this.currentUser = currentUser;
  }

  setCommonFields(isNewDocument: boolean, data: any) {
    const currentDateTimestamp = CollectionHelper.toTimestamp(new Date());

    if (isNewDocument) {
      data.authorUid = this.currentUser.uid;
      data.createdAt = currentDateTimestamp;
    }
    
    data.editedAt = currentDateTimestamp;
  }
  
  static toTimestamp = (date: Date) => {
    return firebase.firestore.Timestamp.fromDate(date);
  };

}

export default CollectionHelper;
