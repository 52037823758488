import Bugsnag from "@bugsnag/js";
import { AxiosInstance } from "axios";
import { UpgradeParams } from '../../functions/src/upgrade/Upgrader';

class UpgraderClient {

  private axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  upgrade = async (params: UpgradeParams) => {
    try {
      const result = await this.axiosInstance.post('/upgrade', params);
      console.log(result.data);
      alert('Upgrade successful, details can be found in log.');
    } catch (error) {
      if (error instanceof Error) {
        alert('Upgrade failed: ' + error);
      }
    }
  }

  upgradeIfNeeded = async (userId: string) => {
    try {
      const body = { userId };
      await this.axiosInstance.post('/upgradeIfNeeded', body);
      console.log('Upgrade successful!');
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      console.log(`Upgrade failed: ${error}`);
    }
  }

}

export default UpgraderClient;
