import Bugsnag from '@bugsnag/js';
import firebase from 'firebase/compat/app';
import InvoiceStatus from 'types/InvoiceStatus';

class InvoiceStatusUpdater {

  private db: firebase.firestore.Firestore;

  private invoiceId: string;
  
  constructor(db: firebase.firestore.Firestore, invoiceId: string) {
    this.db = db;
    this.invoiceId = invoiceId;
  }

  setStatus = async (newStatus: InvoiceStatus) => {    
    try {
      const invoiceRef = this.db.collection('invoices').doc(this.invoiceId);
      await invoiceRef.update('status', newStatus);
    }
    catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  }

}

export default InvoiceStatusUpdater;
