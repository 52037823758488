import Firebase from "config/Firebase";
import 'firebase/compat/storage';
import FileUploader from "./FileUploader";

/// Takes care of uploading signature files to the Firebase Cloud Storage.
class SignatureFileUploader {

  static readonly maxFileSize = 0.5 * 1024 * 1024; // 0.5 MB
  
  private readonly fileUploader: FileUploader;

  constructor(firebase: Firebase) {
    this.fileUploader = new FileUploader(firebase, SignatureFileUploader.maxFileSize, null);
  }

  async uploadFile(file: File, organizationId: string) {
    const path = `organizations/${organizationId}/signatures`;
    return this.fileUploader.uploadFile(file, path);
  }

  isFileTooLarge(file: File) {
    return this.fileUploader.isFileTooLarge(file);
  }

}

export default SignatureFileUploader;
