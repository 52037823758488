import CollectionHelper from "./CollectionHelper";
import firebase from 'firebase/compat/app';
import LastInvoicesPerYear from "types/LastInvoicesPerYear";
import InvoiceData from "types/InvoiceData";
import LastInvoiceSetter from "./LastInvoiceSetter";
import SuccessorInvoiceData from "types/SuccessorInvoiceData";

class InvoiceSaver {

  private db: FirebaseFirestore.Firestore;
  
  private currentUser: firebase.User;

  private collectionHelper: CollectionHelper;

  private invoiceId: string | undefined;

  private data: InvoiceData;

  private organizationId: string;

  private batch: FirebaseFirestore.WriteBatch;
  
  private lastInvoices: LastInvoicesPerYear[] | undefined;
  
  private fromInvoiceId: string | undefined;

  constructor(db: FirebaseFirestore.Firestore, currentUser: firebase.User, invoiceId: string | undefined, data: InvoiceData, organizationId: string, lastInvoices: LastInvoicesPerYear[] | undefined, fromInvoiceId: string | undefined) {
    this.db = db;
    this.currentUser = currentUser;
    this.collectionHelper = new CollectionHelper(this.currentUser);
    this.invoiceId = invoiceId;
    this.data = data;
    this.organizationId = organizationId;
    this.batch = this.db.batch();
    this.lastInvoices = lastInvoices;
    this.fromInvoiceId = fromInvoiceId;
  }

  save = async () => {
    // If the partner is new, save it to the partners collection.
    if (this.isNewPartner()) {
      delete this.data.partner.id;

      // Equip the partner document with common fields.
      this.collectionHelper.setCommonFields(true, this.data.partner);

      const partnerRef = this.db.collection('partners').doc();
      this.batch.set(partnerRef, this.data.partner);

      this.data.partner.id = partnerRef.id;
    }

    // Equip the invoice document with common fields.
    this.collectionHelper.setCommonFields(this.isNewInvoice(), this.data);

    // Save the invoice document.
    const invoiceRef = this.getInvoiceRef();
    this.batch.set(invoiceRef, this.data);

    if (this.isNewInvoice()) {
      // If the invoice is new, then also update last invoice number.
      this.saveLastInvoice(invoiceRef.id);

      // If the invoice is new and it's created from some other invoice, let's update the other invoice as well.
      if (this.fromInvoiceId) {
        this.saveFromInvoice(this.fromInvoiceId, invoiceRef.id);
      }
    }

    // Commit the changes.
    await this.batch.commit();

    return invoiceRef.id;
  }

  private isNewInvoice() {
    return !this.invoiceId;
  }

  private isNewPartner() {
    return !this.data.partner.id || this.data.partner.id.trim().length === 0;
  }
  
  private saveLastInvoice(invoiceId: string) {
    if (!this.data.invoiceTypeId) {
      throw new Error('Invoice type not set on invoice data');
    }
    
    const lastInvoiceSetter = new LastInvoiceSetter(this.lastInvoices);    
    const lastInvoicesPerYear = lastInvoiceSetter.setLastInvoice(this.data.invoiceTypeId!, this.data.invoiceNumberYear, this.data.invoiceNumber, invoiceId);
    
    const organizationRef = this.db.collection('organizations').doc(this.organizationId);
    this.batch.update(organizationRef, {
      'lastInvoices': lastInvoicesPerYear
    });
  };
  
  private saveFromInvoice(fromInvoiceId: string, invoiceId: string) {
    const fromInvoiceRef = this.db.collection('invoices').doc(fromInvoiceId);
    const successorInvoice: SuccessorInvoiceData = {
      invoiceId: invoiceId,
      invoiceNumber: this.data.invoiceNumber,
      invoiceNumberYear: this.data.invoiceNumberYear,
      invoiceNumberPrefixText: this.data.invoiceNumberPrefixText
    };
    this.batch.update(fromInvoiceRef, {
      'successorInvoices': firebase.firestore.FieldValue.arrayUnion(successorInvoice)
    });
  };

  private getInvoiceRef() {
    if (!this.invoiceId) {
      return this.db.collection('invoices').doc();  
    }

    return this.db.collection('invoices').doc(this.invoiceId);
  }

}

export default InvoiceSaver;
