import { useCallback, useState } from 'react';
import firebase from 'firebase/compat/app';
import Organization from 'types/Organization';
import InvoiceStatus from 'types/InvoiceStatus';
import { useFirebase } from 'config/Firebase';
import InvoiceNumberComponentsParser from 'utils/InvoiceNumberComponentsParser';

const useDocumentsFilter = (onClear?: () => void | undefined) => {

  const [visible, setVisible] = useState(false);
  const [partner, setPartner] = useState<Organization | null>(null);
  const [status, setStatus] = useState<InvoiceStatus | null>(null);
  const [invoiceNumberText, setInvoiceNumberText] = useState('');
  const firebaseContext = useFirebase();

  const getInvoiceNumberComponents = useCallback(() => {    
    return InvoiceNumberComponentsParser.parse(invoiceNumberText);
  }, [ invoiceNumberText ]);
  
  const getNumberOfFiltersApplied = () => {
    let number = 0;

    if (partner) {
      number++;
    }

    if (status) {
      number++;
    }

    if (getInvoiceNumberComponents()) {
      number++;
    }

    return number;
  };

  const isAnyFilterApplied = getNumberOfFiltersApplied() > 0 ? true : false;
  const isSortEnabled = getNumberOfFiltersApplied() < 2 ? true : false;
  
  const clear = () => {
    if (!isAnyFilterApplied) {
      return;
    }

    setPartner(null);
    setStatus(null);
    setInvoiceNumberText('');

    if (onClear) {
      onClear();
    }
  }

  const queryHandle = useCallback((query: firebase.firestore.Query<firebase.firestore.DocumentData>) => {
    if (partner) {
      query = query.where('partner.id', '==', partner.id);
    }

    if (status) {
      query = query.where('status', '==', status);
    }
  
    const invoiceNumberComponents = getInvoiceNumberComponents();
    if (invoiceNumberComponents) {
      const dateFrom = new Date(`${invoiceNumberComponents.year}-01-01`);
      const dateTo = new Date(`${invoiceNumberComponents.year}-12-31`);
      query = query
        .where('invoiceNumber', '==', invoiceNumberComponents.number)
        .where('createdAt', '>', dateFrom)
        .where('createdAt', '<', dateTo);
    }

    return query;
  }, [ partner, status, getInvoiceNumberComponents ]);

  const toggleFilter = () => {
    // Clear filter from query when we stop filtering.
    if (visible) {
      clear();
    }

    const newState = !visible;
    setVisible(newState);
    
    firebaseContext!.firebase.logEvent('toggle_item_list_filter', { item_category: 'documents', visible: newState });
  };
  
  return {
    visible,
    partner,
    status,
    invoiceNumberText,
    isAnyFilterApplied,
    setPartner,
    setStatus,
    setInvoiceNumberText,
    clear,
    queryHandle,
    toggleFilter,
    isSortEnabled
  };

};

export default useDocumentsFilter;
